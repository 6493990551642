.header {
    background-color: #ffffff;
}

.header-link {
    margin: 0 15px;
    text-decoration: none;
    color: #000;
    font-weight: 500;
    font-size: 18px;
}

.header-link:hover {
    color: #7a57d1c3;
}

.header-logo {
    font-size: 30px;
    font-weight: bold;
    color: #5d41a7c4; /* Matches purple shade in design */
}

.footer {
    background-color: #111827;
    color: #ffffffbe;
}

.footer-link {
    color: #ffffffbe;
    text-decoration: none;
}

.main-content {
    background-color: #ffffff;
}

.custom-sidebar {
    background-color: #ffffff;
}

.sidebar-link {
    color: #000;
}

.sidebar-link:hover {
    color: #7a57d1c3;
}

.report-link {
    color: #7a57d1ba;
    text-decoration: none;
}

.report-link:hover {
    color: #7a57d1c3;
}

.page-link {
    color: #000;
    text-decoration: none;
}

.page-link:hover {
    color: #7a57d1c3;
}

/* Add these styles to your CSS file */
.settings-switch .form-check-input:checked {
    background-color: #7a57d1ba;  /* Color when switched on */
    border-color: #7a57d1ba;
}
  
.settings-switch .form-check-input:focus {
    border-color: #7a57d1ba;
    box-shadow: 0 0 0 0.25rem rgba(122, 87, 209, 0.25);  /* Focus color */
}

.custom-button {
    background-color: #7a57d1c3;
    color: #ffffff;
    box-shadow: none !important;
    outline: none !important;
}

.custom-button:hover {
    background-color: #7a57d1;
    color: #ffffff;
    box-shadow: none !important;
    outline: none !important;
}

.custom-button:focus {
    box-shadow: none !important;
    outline: none !important;
}

.custom-feature-icon {
    color: #7a57d1c3;
}

.signup-link {
    margin: 0 10px;
    text-decoration: none;
    color: #000;
    font-weight: 500;
    font-size: 14px;
}

.signup-link:hover {
    color: #7a57d1c3;
}

.settings-range::-webkit-slider-thumb {
background: #7a57d1c3; /* Change this color to your preferred color */
    border: none;
}

.settings-range::-moz-range-thumb {
    background: #7a57d1c3; /* Change this color to your preferred color */
    border: none;
}

.settings-range::-ms-thumb {
    background: #7a57d1c3; /* Change this color to your preferred color */
    border: none;
}

/* Optional: Style the track (line) */
.settings-range::-webkit-slider-runnable-track {
    background: #dee2e6;
}

.settings-range::-moz-range-track {
    background: #dee2e6;
}

.settings-range::-ms-track {
    background: #dee2e6;
}

.report-progress-bar {
    min-height: 30px;
}